import React, { useState, useEffect, FunctionComponent } from 'react';
import { Grid, CardActions, CardContent, Card, Button, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, PageProps } from 'gatsby';
import { Helmet } from "react-helmet";
import { WindowLocation } from '../types/PageProps';
import Layout from '../components/layout';
import theme from '../theme';
import imageOne from '../images/Picture1.jpg';
import imageThree from '../images/Picture3.jpg';
import imageFour from '../images/Picture4.jpg';
import imageFive from '../images/Picture5.jpg';
import imageSix from '../images/Picture6.jpg';
import imageSeven from '../images/Picture7.jpg';
import imageEight from '../images/Picture8.jpg';
import imageNine from '../images/Picture9.jpg';
import imageEleven from '../images/Picture11.jpg';
import imageTwelve from '../images/Picture12.jpg';
import imageThirteen from '../images/pictureThirteen.jpg';
import imageFourteen from '../images/IMG_1988.jpg';
import imageFifteen from '../images/IMG_1987.jpg';

const secondary = theme.palette.secondary.main;

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    marginTop: 25,
    backgroundColor: 'transparent',
    height: 625,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  text: {
    fontFamily: 'Raleway',
  }
});

const MeetDolores: FunctionComponent<PageProps> = ({ location }) => {
  const classes = useStyles();
  const [locationState, setLocationState] = useState<WindowLocation<unknown> | undefined>(location);

  useEffect(() => {
    if (location) {
      setLocationState(locationState);
    };
  }, []);

  const pictures = [
    {
      id: 0,
      caption: 'Kim Vo, Educator from Schwarzkopf - met in Nashville in 2014',
      image: imageOne
    },
    {
      id: 2,
      caption: 'Sonja Dove-Wella, one of my favorite educators! - Always says hello when I see him on the road.',
      image: imageThree
    },
    {
      id: 3,
      caption: 'Robert Cromeans, works for Paul Mitchell - Wild man and great educator',
      image: imageFour
    },
    {
      id: 4,
      caption: "Kim Vo, known in Hollywood as 'Mr. Blonde Me!'",
      image: imageFive
    },
    {
      id: 5,
      caption: 'Damien Carney, Schwarzkopf educator and Zotos Alumni - New Orleans',
      image: imageSix
    },
    {
      id: 6,
      caption: 'Color Model! The long and the short of it, sometimes I just feel so short.',
      image: imageSeven
    },
    {
      id: 7,
      caption: 'Martin Parsons, master in the art of formal styles - Hilarious on stage',
      image: imageEight
    },
    {
      id: 8,
      caption: 'Martino Cartier, educator and philanthropist - Wonderful man, always remembers me and says hello!',
      image: imageNine
    },
    {
      id: 9,
      caption: 'Having fun in Fort Lauderdale, learned a lot as well',
      image: imageEleven
    },
    {
      id: 10,
      caption: 'Wynn Claybauch, motivational speaker and author - learned a lot from him',
      image: imageTwelve
    },
    {
      id: 11,
      caption: 'Tabitha and I',
      image: imageThirteen
    },
    {
      id: 12,
      caption: "Tabitha's Book",
      image: imageFourteen
    },
    {
      id: 13,
      caption: 'My lovely daughter Lacey',
      image: imageFifteen
    }
  ];



  return (
    <Layout location={location}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dolores Hair Designers</title>
        <link rel="canonical" href="http://doloreshairdesigners.com/dolores" />
      </Helmet>
      <div>
        <Card className={classes.root} elevation={24}>
          <CardContent>
            <Typography className={classes.text} style={{color: secondary, fontWeight: 'bold'}} gutterBottom variant="h3" component="h2">
              Dolores Meeks
            </Typography>
            <Typography className={classes.text} gutterBottom variant="h6">
              Salon Owner
            </Typography>
            <Typography className={classes.text} variant="body1" color="textSecondary" component="p">
              Dolores has been the owner and a stylist of Dolores & Company for over 
              thirty-five years. Dolores traveled extensively, teaching and doing platform work 
              for Zotos International for twenty years. In 2008 Dolores trained with Cathy Partin,
              an American board certified hair colorist. This course teaches the art of 
              color concentration, which allows Dolores to achieve maximum gray coverage with
              out fading! Most recently, Dolores attended the "Energizing Summit" in Los 
              Angeles California, studying all aspects of hair coloring. Please take a moment 
              to look over the work on this page. All styles were done in the salon by Dolores & Company.
              If you are interested in scheduling an appointment please place a phone call!              
            </Typography>
            <Typography className={classes.text} variant="subtitle1" style={{marginTop: 10}}>
              Contact: (614) 889-4808
            </Typography>         
          </CardContent>
          <CardActions>
            <Button 
              component={Link} 
              to="https://www.schedulicity.com/scheduling/dhdpt8/services" 
              color="primary" 
              variant="contained"
              style={{marginLeft: 'auto', marginRight: 'auto'}}
            >
              schedule now
            </Button>
          </CardActions>
        </Card>
      </div>
      <Grid container alignContent='center' justifyContent="center" spacing={10} style={{marginTop: 100}}>
        {pictures.map(item => (
          <Grid item>
            <Card style={{width: 300, height: 515, backgroundColor: 'transparent'}} elevation={24}>
              <CardMedia component='img' height="400" width="50" alt="image" image={item.image} />
              <CardContent>
                <Typography style={{fontFamily: 'Raleway'}}>
                  {item.caption}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

export default MeetDolores;